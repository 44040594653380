<template>
  <v-row>
    <v-col cols="12"> Ceci est bien la page d'accueil </v-col>
  </v-row>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped></style>
